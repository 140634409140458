<template>
    <div>
        <section class="component-box">
            <label-outside-input-select
                v-if="list_tiktok_accounts.length"
                id="tg_3"
                :label="$t('common.tiktok_business')"
            >
                <el-select
                    :value="selected_tiktok_business"
                    value-key="core_user_id"
                    size="small"
                    filterable
                    :placeholder="$t('input.placeholder.select_tiktok_account')"
                    class="tablet:w-[240px] desktop:w-[280px] mb-2"
                    @change="handleChangeTiktokAccount"
                >
                    <el-option-group :label="$t('common.tiktok_business')">
                        <el-option
                            v-for="item in list_tiktok_accounts"
                            :key="item.core_user_id"
                            :label="
                                item.custom_info.display_name || item.user_name
                            "
                            :value="item"
                        >
                            <div class="flex items-center py-1 space-x-2">
                                <el-avatar :size="36">
                                    <img
                                        v-if="item.custom_info.avatar_url"
                                        class="object-cover"
                                        :src="`${VUE_APP_ATOSA_TIKTOK_SERVER_STATIC}/${item.custom_info.avatar_url}`"
                                        alt=""
                                    />
                                    <p v-else>{{ item.user_name }}</p>
                                </el-avatar>
                                <div class="flex flex-col">
                                    <span
                                        class="leading-5 text-xs font-semibold"
                                    >
                                        {{
                                            item.custom_info.display_name ||
                                            item.user_name
                                        }}
                                    </span>
                                    <span class="component-text-desc">
                                        #{{ item.core_user_id }}
                                    </span>
                                </div>
                            </div>
                        </el-option>
                    </el-option-group>
                </el-select>
            </label-outside-input-select>

            <el-tabs
                v-model="tab"
                v-loading="m__loading"
                class="el-tabs-custom"
            >
                <el-tab-pane
                    :label="$t('page.payment.ad_account_list')"
                    name="ad_account_list"
                >
                    <AdAccountListComponent
                        :user_info="user_info"
                        :selected_tiktok_business="selected_tiktok_business"
                        @getUserInfo="getUserInfo"
                    ></AdAccountListComponent>
                </el-tab-pane>
                <el-tab-pane
                    :label="$t('page.payment.ad_account_transaction_history')"
                    name="ad_account_transaction"
                >
                    <AdAccountTransactionHistoryComponent
                        :selected_tiktok_business="selected_tiktok_business"
                    ></AdAccountTransactionHistoryComponent>
                </el-tab-pane>
            </el-tabs>
        </section>

        <AtosaAccountTransactionHistoryComponent
            class="mt-4"
        ></AtosaAccountTransactionHistoryComponent>
    </div>
</template>

<script>
import { getInfo } from '@/services/atosa/auth'
import { getTiktokAccountsNotPackage } from '@/services/atosa-tiktok-ads/tiktok-account'
import AdAccountListComponent from './ad-account-list'
import AdAccountTransactionHistoryComponent from './ad-account-transaction-history'
import AtosaAccountTransactionHistoryComponent from './atosa-account-transaction-history'

export default {
    components: {
        AdAccountListComponent,
        AdAccountTransactionHistoryComponent,
        AtosaAccountTransactionHistoryComponent
    },

    data() {
        return {
            VUE_APP_ATOSA_TIKTOK_SERVER_STATIC:
                process.env.VUE_APP_ATOSA_TIKTOK_SERVER_STATIC,
            selected_tiktok_business: null,
            list_tiktok_accounts: [],
            tab: 'ad_account_list',
            user_info: {}
        }
    },

    computed: {
        c__selected_tiktok_business() {
            return this.$store.getters.tiktokBusiness
                .s__selected_tiktok_business
        }
    },

    watch: {
        c__selected_tiktok_business() {
            this.getTiktokAccountsNotPackage()
        }
    },

    async beforeMount() {
        await this.getUserInfo()
        await this.getTiktokAccountsNotPackage()
    },

    methods: {
        async getUserInfo() {
            const response = await getInfo()
            this.user_info = response.data
        },

        handleChangeTiktokAccount(value) {
            this.selected_tiktok_business = value
        },

        async getTiktokAccountsNotPackage() {
            this.m__loading = true
            try {
                const response = await getTiktokAccountsNotPackage()
                console.log(response)

                if (response.code === 0 && response.data.list.length > 0) {
                    this.list_tiktok_accounts = response.data.list
                    if (this.c__selected_tiktok_business) {
                        const temp = this.list_tiktok_accounts.find(
                            (item) =>
                                item.id === this.c__selected_tiktok_business.id
                        )
                        if (temp) {
                            this.selected_tiktok_business = temp
                        } else {
                            this.selected_tiktok_business =
                                this.list_tiktok_accounts[0]
                        }
                    } else {
                        this.selected_tiktok_business =
                            this.list_tiktok_accounts[0]
                    }
                }
            } catch (error) {
                console.error(error)
            }
            this.m__loading = false
        },

        async redirectToPaymentPage() {
            // this.$router.push('/payment/webview')
            try {
                await this.p__OpenLinkOnNewWindow(
                    `/payment/webview?extra=${JSON.stringify({
                        channel: 'tiktok_ads'
                    })}&amount_options=50,1000000,2000000,5000000,10000000,15000000,20000000,30000000&amount_limit=500000`
                )
            } catch (e) {
                console.error(e)
            }
        }
    }
}
</script>
