<template>
    <div class="component-box">
        <div class="flex items-center mb-4 space-x-2">
            <p class="font-semibold">
                {{ $t('page.payment.atosa_account_transaction_history') }}
            </p>

            <div class="!ml-auto"></div>

            <el-date-picker
                v-model="ranger_date"
                class="w-[30%]"
                size="small"
                type="daterange"
                popper-class="custom-el-date"
                unlink-panels
                :start-placeholder="$t('date_picker.placeholder.start')"
                :end-placeholder="$t('date_picker.placeholder.end')"
                :format="$t('common.format_value_date_picker')"
                value-format="yyyy-MM-dd"
                :picker-options="m__picker_options"
                @change="getTransactionList"
            >
            </el-date-picker>

            <el-button
                type="default"
                size="small"
                icon="el-icon-refresh"
                @click="getTransactionList"
            ></el-button>
        </div>

        <!--        <pre>{{ transaction_display_list }}</pre>-->

        <el-table
            v-loading="m__loading"
            max-height="400"
            :data="transaction_display_list"
            show-summary
            :summary-method="getSummaries"
        >
            <el-table-column label="#" type="index" width="60" />

            <el-table-column :label="$t('common.transaction_time')">
                <template slot-scope="scope">
                    {{
                        scope.row.created_time
                            | f__format_moment($t('common.format_fulldate'))
                    }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.transaction_type')">
                <template slot-scope="scope">
                    {{ convertUserTransactionType(scope.row.type) }}
                </template>
            </el-table-column>
            <el-table-column prop="money" :label="$t('common.cash_amount')">
                <template slot-scope="scope">
                    {{ scope.row.type.startsWith('add-') ? '+' : '-' }}
                    {{ scope.row.money | f__format_currency_vnd }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.transaction_details')">
                <template slot-scope="scope">
                    {{ translateTransactionContent(scope.row) }}
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import moment from 'moment'
import { drop as _drop, take as _take } from 'lodash'
import { getUserTransactionHistory } from '@/services/atosa-tiktok-ads/payment'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import rangerDateMixin from '@/plugins/mixins/ranger-date'

export default {
    mixins: [paginationDataMixin, rangerDateMixin],

    data() {
        return {
            ranger_date: [
                new Date(new Date().getTime() - 3600 * 1000 * 24 * 30),
                new Date()
            ],
            transaction_list: []
        }
    },

    computed: {
        transaction_display_list() {
            const limit = this.m__page_info.page_size
            const skip = limit * (this.m__page_info.page - 1)

            return _take(_drop(this.transaction_list, skip), limit)
        }
    },

    beforeMount() {
        this.getTransactionList()
    },

    methods: {
        translateTransactionContent(data) {
            if (data.type === 'sub-money-store') {
                if (data.is_buy_package && !data.message.includes('add fund')) {
                    return data.message
                        .replace(
                            'Mua thành công gói',
                            this.$t('page.payment.success_purchased')
                        )
                        .replace(
                            'mã giao dịch',
                            this.$t('page.payment.transaction_code')
                        )
                } else {
                    if (data.extra_data?.extra?.advertiser_name) {
                        return `${this.$t(
                            'page.payment.add_balance_to_ads_account'
                        )} ${data.extra_data?.extra?.advertiser_name}`
                    }
                    return ''
                }
            }
            if (data.type === 'add-money-store') {
                return this.$t('page.payment.add_balance_to_atosa_account')
            }
        },

        convertUserTransactionType(value) {
            if (value === 'add-money-store') {
                return this.$t('page.payment.add_money_store')
            }
            if (value === 'add-price-order-cancel-order') {
                return this.$t('page.payment.add_price_order_cancel_order')
            }
            if (value === 'sub-buy-point') {
                return this.$t('page.payment.sub_buy_point')
            }
            if (value === 'sub-money-store') {
                return this.$t('page.payment.sub_money_store')
            }
            if (value === 'sub-price-order') {
                return this.$t('page.payment.sub_price_order')
            }

            return ''
        },

        async getTransactionList() {
            this.m__loading = true
            try {
                const start_time = moment(this.ranger_date[0]).format(
                    'YYYY-MM-DD'
                )
                const end_time = moment(this.ranger_date[1]).format(
                    'YYYY-MM-DD'
                )
                const response = await getUserTransactionHistory(
                    start_time,
                    end_time
                )
                this.transaction_list = response.data
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                const { property } = column

                const values = data.map((item) => {
                    if (property) {
                        const pathArray = property.split('.')
                        if (pathArray.length > 1) {
                            let value = item
                            for (const key of pathArray) {
                                value = value[key]
                            }

                            return Number(value)
                        }
                    }

                    return Number(item[property])
                })

                if (!values.every((value) => isNaN(value))) {
                    const total = this.calculateValue(values, property, data)

                    sums[index] = total
                } else {
                    sums[index] = ''
                }
            })
            return sums
        },

        calculateValue(values, column_property, data) {
            let total = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                    return prev + curr
                } else {
                    return prev
                }
            }, 0)

            if (['money'].some((item) => column_property === item)) {
                total = data.reduce((prev, curr) => {
                    const value = Number(curr.money)
                    if (!isNaN(value) && curr.type.startsWith('add-')) {
                        return prev + curr.money
                    } else {
                        return prev
                    }
                }, 0)
                total = `+ ${this.p__renderMoneyByCurrency(
                    total,
                    data[0].currency_unit
                )}`
            }
            return total
        }
    }
}
</script>
