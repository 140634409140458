<template>
    <div>
        <div class="flex justify-between mb-4">
            <div class="ml-auto"></div>
            <el-input
                v-model="search_keyword"
                size="small"
                :placeholder="$t('input.placeholder.search_by_name_or_id')"
                class="w-[25%]"
                suffix-icon="el-icon-search"
            ></el-input>
        </div>

        <el-alert
            :title="$t('page.payment.payment_note')"
            type="success"
            class="mb-2"
            :closable="false"
        >
        </el-alert>

        <el-table
            v-loading="loading.get_data"
            :data="ad_account_list_display_list"
            max-height="400"
            show-summary
            :summary-method="getSummaries"
        >
            <el-table-column label="#" type="index" width="60" />

            <el-table-column :label="$t('common.ad_account')">
                <template slot-scope="scope">
                    <div>ID: {{ scope.row.advertiser_id }}</div>
                    <div>{{ scope.row.name }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.business_center')">
                <template slot-scope="scope">
                    <div>ID: {{ scope.row.owner_bc_id }}</div>
                    <div>{{ scope.row.owner_bc_name }}</div>
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.status')">
                <template slot-scope="scope">
                    <!--                                        {{ scope.row.data.status }}-->
                    <el-tag
                        v-if="scope.row.status === 'STATUS_ENABLE'"
                        size="medium"
                        type="success"
                    >
                        {{ $t('common.enable') }}
                    </el-tag>
                    <el-tag
                        v-if="scope.row.status === 'STATUS_LIMIT'"
                        size="medium"
                        type="danger"
                    >
                        {{ $t('common.suspended') }}
                    </el-tag>
                    <el-tag
                        v-if="scope.row.status === 'STATUS_PENDING_CONFIRM'"
                        size="medium"
                        type="warning"
                    >
                        {{ $t('common.in_review') }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.timezone')">
                <template slot-scope="scope">
                    {{ scope.row.display_timezone }}
                </template>
            </el-table-column>
            <el-table-column prop="balance" :label="$t('common.balance')">
                <template slot-scope="scope">
                    {{ scope.row.balance | f__format_currency_vnd }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.option')" width="150px">
                <template slot-scope="scope">
                    <el-button
                        type="primary"
                        size="small"
                        icon="el-icon-plus"
                        @click="showAddFundDialog(scope.row)"
                    >
                        {{ $t('page.payment.add_fund') }}
                    </el-button>
                </template>
            </el-table-column>
        </el-table>

        <el-pagination
            class="mt-2"
            :current-page.sync="m__page_info.page"
            :page-size="m__page_info.page_size"
            layout="prev, pager, next, total"
            :total="ad_account_list_filter_list.length"
        />

        <el-dialog
            :append-to-body="true"
            :visible.sync="add_fund_dialog"
            :close-on-click-modal="true"
            width="540px"
        >
            <div slot="title">
                <p class="text-lg font-semibold">
                    {{ $t('page.payment.add_balance_title') }}
                </p>
            </div>
            <div>
                <div class="flex">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="160"
                        height="160"
                        viewBox="0 0 320 320"
                        fill="none"
                        class="mdl-js"
                    >
                        <path
                            d="M87.3345 209.363L89.1505 216.1L95.4469 219.107L88.7098 220.923L85.7029 227.22L83.8869 220.483L77.5904 217.476L84.3276 215.66L87.3345 209.363Z"
                            fill="#D5DDDC"
                        />
                        <mask
                            id="path-2-outside-1_598_56084"
                            maskUnits="userSpaceOnUse"
                            x="110.428"
                            y="-2.60119"
                            width="128.691"
                            height="128.691"
                            fill="black"
                        >
                            <rect
                                fill="white"
                                x="110.428"
                                y="-2.60119"
                                width="128.691"
                                height="128.691"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M143.445 93.6396C160.98 110.942 189.221 110.755 206.523 93.2204C223.826 75.6861 223.638 47.4451 206.104 30.1424C188.57 12.8396 160.329 13.0273 143.026 30.5616C125.723 48.0959 125.911 76.3369 143.445 93.6396ZM165.135 71.6556C170.53 76.9796 179.22 76.9218 184.544 71.5266C189.868 66.1315 189.81 57.4419 184.415 52.118C179.02 46.7941 170.33 46.8519 165.006 52.247C159.682 57.6422 159.74 66.3317 165.135 71.6556Z"
                            />
                        </mask>
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M143.445 93.6396C160.98 110.942 189.221 110.755 206.523 93.2204C223.826 75.6861 223.638 47.4451 206.104 30.1424C188.57 12.8396 160.329 13.0273 143.026 30.5616C125.723 48.0959 125.911 76.3369 143.445 93.6396ZM165.135 71.6556C170.53 76.9796 179.22 76.9218 184.544 71.5266C189.868 66.1315 189.81 57.4419 184.415 52.118C179.02 46.7941 170.33 46.8519 165.006 52.247C159.682 57.6422 159.74 66.3317 165.135 71.6556Z"
                            fill="#A1D786"
                        />
                        <path
                            d="M206.352 93.0518C189.143 110.492 161.054 110.678 143.614 93.4688L143.277 93.8105C160.905 111.206 189.298 111.018 206.694 93.389L206.352 93.0518ZM205.935 30.3132C223.375 47.5228 223.562 75.6119 206.352 93.0518L206.694 93.389C224.09 75.7603 223.901 47.3674 206.273 29.9715L205.935 30.3132ZM143.197 30.7302C160.407 13.2903 188.496 13.1036 205.935 30.3132L206.273 29.9715C188.644 12.5757 160.251 12.7644 142.855 30.393L143.197 30.7302ZM143.614 93.4688C126.174 76.2592 125.987 48.1701 143.197 30.7302L142.855 30.393C125.459 48.0217 125.648 76.4146 143.277 93.8105L143.614 93.4688ZM184.373 71.3581C179.142 76.6589 170.605 76.7156 165.304 71.4848L164.967 71.8265C170.456 77.2435 179.298 77.1847 184.715 71.6952L184.373 71.3581ZM184.246 52.2889C189.547 57.5197 189.604 66.0573 184.373 71.3581L184.715 71.6952C190.132 66.2057 190.073 57.3642 184.583 51.9472L184.246 52.2889ZM165.177 52.4156C170.408 47.1148 178.946 47.058 184.246 52.2889L184.583 51.9472C179.094 46.5302 170.252 46.5889 164.835 52.0785L165.177 52.4156ZM165.304 71.4848C160.003 66.254 159.946 57.7164 165.177 52.4156L164.835 52.0785C159.418 57.568 159.477 66.4095 164.967 71.8265L165.304 71.4848Z"
                            fill="#685959"
                            mask="url(#path-2-outside-1_598_56084)"
                        />
                        <mask
                            id="path-4-outside-2_598_56084"
                            maskUnits="userSpaceOnUse"
                            x="111.475"
                            y="-7.03479"
                            width="128.691"
                            height="128.691"
                            fill="black"
                        >
                            <rect
                                fill="white"
                                x="111.475"
                                y="-7.03479"
                                width="128.691"
                                height="128.691"
                            />
                            <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M144.492 89.206C162.027 106.509 190.267 106.321 207.57 88.7868C224.873 71.2525 224.685 43.0115 207.151 25.7088C189.617 8.40604 161.376 8.59374 144.073 26.128C126.77 43.6623 126.958 71.9033 144.492 89.206ZM166.182 67.2221C171.577 72.546 180.267 72.4882 185.591 67.0931C190.915 61.6979 190.857 53.0084 185.462 47.6844C180.067 42.3605 171.377 42.4183 166.053 47.8134C160.729 53.2086 160.787 61.8981 166.182 67.2221Z"
                            />
                        </mask>
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M144.492 89.206C162.027 106.509 190.267 106.321 207.57 88.7868C224.873 71.2525 224.685 43.0115 207.151 25.7088C189.617 8.40604 161.376 8.59374 144.073 26.128C126.77 43.6623 126.958 71.9033 144.492 89.206ZM166.182 67.2221C171.577 72.546 180.267 72.4882 185.591 67.0931C190.915 61.6979 190.857 53.0084 185.462 47.6844C180.067 42.3605 171.377 42.4183 166.053 47.8134C160.729 53.2086 160.787 61.8981 166.182 67.2221Z"
                            fill="#D8EECD"
                        />
                        <path
                            d="M207.399 88.6182C190.19 106.058 162.101 106.245 144.661 89.0352L144.324 89.3769C161.952 106.773 190.345 106.584 207.741 88.9554L207.399 88.6182ZM206.982 25.8796C224.422 43.0893 224.609 71.1783 207.399 88.6182L207.741 88.9554C225.137 71.3267 224.948 42.9338 207.32 25.5379L206.982 25.8796ZM144.244 26.2966C161.453 8.85666 189.542 8.66997 206.982 25.8796L207.32 25.5379C189.691 8.14211 161.298 8.33082 143.902 25.9595L144.244 26.2966ZM144.661 89.0352C127.221 71.8256 127.034 43.7365 144.244 26.2966L143.902 25.9595C126.506 43.5881 126.695 71.981 144.324 89.3769L144.661 89.0352ZM185.42 66.9245C180.189 72.2253 171.652 72.282 166.351 67.0512L166.014 67.3929C171.503 72.8099 180.345 72.7511 185.762 67.2616L185.42 66.9245ZM185.293 47.8553C190.594 53.0861 190.651 61.6237 185.42 66.9245L185.762 67.2616C191.179 61.7721 191.12 52.9306 185.63 47.5136L185.293 47.8553ZM166.224 47.982C171.455 42.6812 179.992 42.6244 185.293 47.8553L185.63 47.5136C180.141 42.0966 171.299 42.1553 165.882 47.6449L166.224 47.982ZM166.351 67.0512C161.05 61.8204 160.993 53.2828 166.224 47.982L165.882 47.6449C160.465 53.1344 160.524 61.9759 166.014 67.3929L166.351 67.0512Z"
                            fill="#685959"
                            mask="url(#path-4-outside-2_598_56084)"
                        />
                        <ellipse
                            cx="189.668"
                            cy="275.979"
                            rx="80.248"
                            ry="24.6606"
                            transform="rotate(-10.1377 189.668 275.979)"
                            fill="#64DFDB"
                        />
                        <ellipse
                            cx="162.488"
                            cy="266.339"
                            rx="80.248"
                            ry="24.6606"
                            transform="rotate(-10.1377 162.488 266.339)"
                            fill="#F79092"
                        />
                        <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M166.827 290.614C210.455 282.813 243.879 265.621 241.482 252.214C241.247 250.901 240.678 249.674 239.801 248.534C225.04 247.092 205.885 248.027 185.327 251.703C141.699 259.504 108.275 276.696 110.673 290.103C110.907 291.416 111.477 292.643 112.354 293.783C127.114 295.225 146.269 294.29 166.827 290.614Z"
                            fill="#017C75"
                        />
                        <path
                            d="M239.79 250.393L236.181 255.574L236.132 255.577L236.099 255.63C235.212 257.043 233.839 258.1 232.112 258.493L232.139 258.61L232.112 258.493L145.926 278.159C140.579 279.378 134.92 276.066 132.705 270.387L63.9135 94.024C62.8415 91.2737 63.3097 88.4444 64.7757 86.4816L64.7757 86.4816L64.7776 86.4789L68.625 81.0216L70.4635 81.7398L70.4547 83.7542L70.4542 83.8799L70.5798 83.8746L154.881 80.3147C157.787 80.1927 160.598 82.0335 161.931 84.9525L162.026 84.9092L161.931 84.9525L236.46 248.09C236.46 248.09 236.46 248.09 236.46 248.09C236.462 248.095 236.464 248.1 236.467 248.108C236.468 248.111 236.47 248.115 236.472 248.12C236.474 248.125 236.476 248.13 236.479 248.135L236.515 248.212L236.599 248.204L238.019 248.062L238.152 248.048L239.79 250.393Z"
                            fill="#4EB8B6"
                            stroke="#333333"
                            stroke-width="0.24"
                        />
                        <path
                            d="M136.597 264.845L67.8051 88.4839C65.9159 83.6402 68.7981 78.5717 73.4871 78.3741L158.773 74.7727C161.68 74.6507 164.491 76.4915 165.824 79.4105L165.919 79.367L165.824 79.4105L240.353 242.549C242.376 246.977 240.257 251.981 236.005 252.951L149.819 272.617C144.472 273.836 138.813 270.524 136.597 264.845Z"
                            fill="#B3E0E0"
                            stroke="#333333"
                            stroke-width="0.24"
                        />
                        <mask
                            id="mask0_598_56084"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="67"
                            y="74"
                            width="175"
                            height="199"
                        >
                            <path
                                d="M136.486 264.888L67.6933 88.5265C65.7809 83.6233 68.6933 78.455 73.482 78.2532L158.768 74.6519C161.729 74.5275 164.582 76.4018 165.933 79.3597L240.462 242.498C242.51 246.98 240.372 252.076 236.032 253.067L149.846 272.733C144.432 273.967 138.719 270.613 136.486 264.888Z"
                                fill="#25F1EB"
                            />
                        </mask>
                        <g mask="url(#mask0_598_56084)">
                            <path
                                d="M163.786 154.019C161.338 153.349 159.191 151.689 157.92 149.355C157.643 148.849 157.412 148.314 157.227 147.75L150.814 149.858L159.396 176.005C160.253 178.967 158.653 182.098 155.752 183.052C154.849 183.349 153.927 183.399 153.049 183.245C151.028 182.892 149.256 181.445 148.564 179.342C147.57 176.319 149.176 173.06 152.152 172.082C152.706 171.9 153.273 171.819 153.823 171.813L152.142 166.7L151.634 165.154C151.088 165.256 150.542 165.38 150.007 165.556C143.5 167.695 139.978 174.821 142.155 181.442C143.49 185.503 146.666 188.447 150.425 189.619C152.793 190.357 155.386 190.397 157.902 189.57C164.408 187.43 167.93 180.305 165.753 173.683L161.395 160.428C164.515 161.439 167.953 161.509 171.278 160.416L169.611 155.347L169.132 153.89C167.339 154.479 165.493 154.489 163.786 154.019Z"
                                fill="white"
                                stroke="#333333"
                                stroke-width="0.24"
                            />
                        </g>
                        <path
                            d="M67.8408 97.2107L67.317 98.2666C67.0482 98.8083 67.0207 99.4378 67.2427 99.9997L70.6718 108.719C70.989 109.524 72.1093 109.572 72.4933 108.798L72.9223 107.933C73.2312 107.31 73.2643 106.585 73.0125 105.935L69.6653 97.2935C69.352 96.4851 68.2268 96.4326 67.8408 97.2107Z"
                            fill="#EBEBEB"
                        />
                        <path
                            d="M71.1017 109.344C70.8614 109.225 70.6709 109.02 70.5678 108.758L67.1388 100.039C66.9068 99.4472 66.9362 98.7843 67.2177 98.2169L67.7415 97.1611C67.9399 96.7612 68.3384 96.5268 68.7849 96.5471C69.2285 96.5683 69.605 96.8386 69.7676 97.2531L73.1148 105.895C73.3765 106.57 73.3425 107.331 73.02 107.981L72.591 108.846C72.3936 109.244 71.9951 109.478 71.5525 109.46C71.3912 109.453 71.2396 109.412 71.1037 109.345L71.1017 109.344ZM69.1321 96.8568C69.0237 96.803 68.9017 96.7719 68.7748 96.7654C68.4178 96.7479 68.0978 96.9377 67.9394 97.2568L67.4156 98.3127C67.1616 98.8249 67.1359 99.4258 67.3461 99.9572L70.7751 108.676C70.905 109.008 71.2085 109.223 71.5636 109.239C71.9196 109.254 72.2387 109.066 72.397 108.747L72.826 107.882C73.1211 107.287 73.1514 106.593 72.9134 105.974L69.5663 97.3324C69.483 97.1192 69.33 96.9525 69.137 96.8567L69.1321 96.8568Z"
                            fill="#323232"
                        />
                        <path
                            d="M196.885 264.104L196.45 264.932C196.244 265.323 195.873 265.605 195.429 265.709L189.592 267.06C189.212 267.147 188.884 266.76 189.049 266.417L189.491 265.5C189.675 265.12 190.027 264.846 190.45 264.751L196.341 263.434C196.733 263.347 197.068 263.756 196.886 264.102L196.885 264.104Z"
                            fill="#333333"
                        />
                        <path
                            d="M189.223 267.122C189.147 267.084 189.079 267.028 189.02 266.957C188.884 266.786 188.856 266.561 188.949 266.369L189.391 265.452C189.591 265.041 189.967 264.746 190.425 264.645L196.317 263.328C196.546 263.277 196.781 263.362 196.925 263.551C197.063 263.73 197.083 263.961 196.982 264.154L196.547 264.981C196.327 265.4 195.929 265.703 195.454 265.814L189.617 267.165C189.481 267.196 189.343 267.178 189.224 267.12L189.223 267.122ZM196.614 263.571C196.545 263.537 196.46 263.519 196.366 263.541L190.474 264.858C190.082 264.946 189.761 265.196 189.591 265.549L189.149 266.466C189.092 266.58 189.109 266.714 189.194 266.82C189.265 266.909 189.401 266.991 189.568 266.951L195.405 265.601C195.816 265.505 196.162 265.242 196.354 264.881L196.789 264.053C196.851 263.934 196.837 263.797 196.753 263.687C196.72 263.643 196.672 263.602 196.615 263.574L196.614 263.571Z"
                            fill="#333333"
                        />
                        <path
                            d="M223.88 136.378L223.976 136.399L224.016 136.31L226.868 129.909L230.69 130.757L230.785 130.778L230.825 130.69L232.647 126.661L232.669 126.611L232.646 126.561L231.622 124.377L231.571 124.27L231.464 124.319L228.857 125.502L230.959 120.742L234.358 121.497L234.452 121.518L234.493 121.431L236.344 117.449L236.368 117.399L236.344 117.349L235.34 115.164L235.286 115.047L235.174 115.109L232.919 116.331L235.559 110.463L235.581 110.413L235.558 110.363L234.539 108.162L234.494 108.065L234.393 108.099L230.803 109.276L230.753 109.293L230.731 109.341L227.986 115.444L222.58 114.243L225.305 108.186L225.327 108.136L225.304 108.086L224.283 105.885L224.238 105.788L224.137 105.822L220.549 106.999L220.499 107.015L220.477 107.064L217.732 113.167L213.872 112.309L213.824 112.299L213.783 112.324L210.788 114.17L210.692 114.229L210.745 114.329L211.888 116.464L211.914 116.513L211.968 116.525L215.858 117.389L213.687 122.303L210.058 121.497L210.009 121.486L209.967 121.513L207.047 123.403L206.955 123.463L207.007 123.56L208.143 125.707L208.169 125.756L208.223 125.768L211.705 126.541L207.805 130.726L207.746 130.789L207.787 130.864L208.938 133.017L208.964 133.065L209.018 133.077L213.626 134.101L213.722 134.122L213.762 134.033L216.614 127.631L221.959 128.818L218.064 133.004L218.005 133.067L218.046 133.143L219.192 135.294L219.218 135.343L219.272 135.355L223.88 136.378ZM223.941 124.58L218.535 123.379L220.705 118.465L226.112 119.666L223.941 124.58Z"
                            fill="#FFE9BC"
                            stroke="#685959"
                            stroke-width="0.24"
                        />
                        <path
                            d="M222.753 134.113L222.849 134.134L222.889 134.044L225.741 127.643L229.563 128.492L229.658 128.513L229.698 128.424L231.519 124.396L231.58 124.261L231.436 124.229L227.662 123.391L229.832 118.477L233.231 119.232L233.325 119.253L233.366 119.165L235.217 115.184L235.28 115.049L235.134 115.016L231.707 114.255L234.432 108.198L234.492 108.063L234.348 108.031L229.74 107.008L229.644 106.987L229.604 107.076L226.859 113.178L221.453 111.978L224.178 105.92L224.238 105.786L224.094 105.754L219.486 104.731L219.39 104.709L219.35 104.799L216.605 110.901L212.744 110.044L212.65 110.023L212.61 110.111L210.758 114.092L210.695 114.227L210.841 114.26L214.731 115.124L212.56 120.038L208.931 119.232L208.835 119.21L208.795 119.3L207.012 123.337L206.953 123.471L207.096 123.503L210.639 124.29L207.807 130.646L207.747 130.78L207.891 130.812L212.499 131.836L212.595 131.857L212.635 131.767L215.487 125.366L220.893 126.567L218.061 132.923L218.001 133.057L218.145 133.089L222.753 134.113ZM222.814 122.315L217.408 121.114L219.578 116.2L224.985 117.401L222.814 122.315Z"
                            fill="#FFF8EB"
                            stroke="#685959"
                            stroke-width="0.24"
                        />
                        <path
                            d="M92.8071 88.0653C95.014 77.7681 109.275 66.7115 109.275 66.7115L113.56 71.3476C113.56 71.3476 100.817 81.1464 98.5434 90.9075C96.2887 100.588 106.544 105.99 118.531 109.004C133.975 112.503 150.568 112.807 150.568 112.807C150.568 112.807 133.245 112.705 118.531 109.004C103.926 105.696 90.3497 99.531 92.8071 88.0653Z"
                            fill="#FF949B"
                        />
                        <path
                            d="M109.275 66.7115C109.275 66.7115 95.014 77.7681 92.8071 88.0653C87.7512 111.655 150.568 112.807 150.568 112.807C150.568 112.807 93.5211 112.469 98.5434 90.9075C100.817 81.1464 113.56 71.3476 113.56 71.3476L109.275 66.7115Z"
                            stroke="#685959"
                            stroke-width="0.24"
                            stroke-linecap="round"
                        />
                        <path
                            d="M90.6587 87.3036C92.8656 77.0064 107.127 65.9498 107.127 65.9498L111.411 70.5858C111.411 70.5858 98.6685 80.3847 96.3949 90.1458C94.1402 99.8258 104.395 105.228 116.383 108.243C131.826 111.741 148.42 112.045 148.42 112.045C148.42 112.045 131.096 111.943 116.383 108.243C101.778 104.934 88.2013 98.7693 90.6587 87.3036Z"
                            fill="#37D4CF"
                        />
                        <path
                            d="M107.127 65.9498C107.127 65.9498 92.8656 77.0064 90.6587 87.3036C85.6028 110.894 148.42 112.045 148.42 112.045C148.42 112.045 91.3727 111.708 96.3949 90.1458C98.6685 80.3847 111.411 70.5858 111.411 70.5858L107.127 65.9498Z"
                            stroke="#685959"
                            stroke-width="0.24"
                            stroke-linecap="round"
                        />
                        <path
                            d="M104.216 74.403L113.574 74.1305L117.588 84.3418L156.971 60.5983L102.307 46.3726L107.793 59.6453L104.216 74.403Z"
                            fill="#323232"
                        />
                        <path
                            d="M107.803 59.6503L102.569 46.5798L155.969 60.4512L107.803 59.6503Z"
                            fill="#EBEBEB"
                            stroke="#685959"
                            stroke-width="0.24"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M107.828 59.9006L121.384 60.0387L104.396 73.9053L107.828 59.9006Z"
                            fill="#D6D5D5"
                        />
                        <path
                            d="M107.828 59.9006L121.384 60.0387L104.396 73.9053L107.828 59.9006Z"
                            fill="black"
                            fill-opacity="0.2"
                        />
                        <path
                            d="M107.828 59.9006L121.384 60.0387L104.396 73.9053L107.828 59.9006Z"
                            fill="#37D4CF"
                        />
                        <path
                            d="M107.828 59.9006L121.384 60.0387L104.396 73.9053L107.828 59.9006Z"
                            stroke="#685959"
                            stroke-width="0.24"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M104.075 74.414L113.938 74.1824L109.94 65.146L104.075 74.414Z"
                            fill="#D6D5D5"
                        />
                        <path
                            d="M104.075 74.414L113.938 74.1824L109.94 65.146L104.075 74.414Z"
                            fill="#323232"
                        />
                        <path
                            d="M104.075 74.414L113.938 74.1824L109.94 65.146L104.075 74.414Z"
                            stroke="#685959"
                            stroke-width="0.24"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M156.469 60.7744L117.641 84.3017L110.089 65.2564L156.469 60.7744Z"
                            fill="#E8E8E8"
                            stroke="#323232"
                            stroke-width="0.24"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M107.751 59.8546L121.656 60.0044L102.305 46.3975L107.751 59.8546Z"
                            fill="#FF949B"
                            stroke="#685959"
                            stroke-width="0.24"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M117.608 84.5386L122.313 63.9728L109.903 65.1486L117.608 84.5386Z"
                            fill="#FF949B"
                            stroke="#685959"
                            stroke-width="0.24"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M104.216 74.403L113.574 74.1305L117.588 84.3418L156.971 60.5983L102.307 46.3726L107.793 59.6453L104.216 74.403Z"
                            stroke="#685959"
                            stroke-width="0.24"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M92.1069 69.0998C90.5013 69.9175 88.5369 69.2788 87.7192 67.6732C86.9015 66.0676 87.5402 64.1032 89.1458 63.2855C90.7514 62.4678 92.7159 63.1065 93.5335 64.7121C94.3512 66.3177 93.7125 68.2822 92.1069 69.0998Z"
                            fill="white"
                            stroke="#685959"
                            stroke-width="0.24"
                        />
                        <path
                            d="M94.9699 79.2791C93.9541 79.7964 92.7113 79.3923 92.1939 78.3765C91.6766 77.3607 92.0807 76.1179 93.0965 75.6005C94.1123 75.0832 95.3552 75.4873 95.8725 76.5031C96.3898 77.5189 95.9857 78.7618 94.9699 79.2791Z"
                            fill="#FF949B"
                            stroke="#685959"
                            stroke-width="0.24"
                        />
                        <path
                            d="M223.515 179.721C224.55 180.235 225.805 179.812 226.318 178.777C226.832 177.742 226.409 176.487 225.374 175.974C224.339 175.46 223.084 175.883 222.57 176.918C222.057 177.953 222.48 179.208 223.515 179.721Z"
                            fill="#F8F8F8"
                        />
                        <path
                            d="M223.48 179.805C222.876 179.505 222.418 178.929 222.298 178.215C222.201 177.641 222.334 177.063 222.67 176.59C223.008 176.116 223.51 175.803 224.082 175.705C225.266 175.506 226.393 176.306 226.591 177.491C226.79 178.675 225.99 179.802 224.805 180C224.336 180.079 223.876 180.001 223.479 179.804L223.48 179.805ZM225.334 176.056C224.969 175.875 224.544 175.803 224.112 175.876C223.584 175.965 223.121 176.253 222.811 176.691C222.5 177.128 222.379 177.66 222.468 178.187C222.651 179.278 223.688 180.016 224.779 179.831C225.87 179.648 226.608 178.611 226.423 177.52C226.312 176.862 225.891 176.333 225.334 176.056Z"
                            fill="#333333"
                        />
                        <path
                            d="M252.203 168.215L250.847 169.033C251.71 167.456 251.632 165.652 250.618 164.169C249.769 162.925 248.391 162.104 246.801 161.886C246.345 160.5 245.253 159.384 243.767 158.795C242.332 158.228 240.75 158.235 239.341 158.781L239.526 158.067L237.656 159.769C236.356 160.856 235.692 162.396 235.86 164.003L236.724 172.261L245.962 171.925C247.399 171.87 248.717 171.341 249.709 170.461L252.203 168.217L252.203 168.215Z"
                            fill="#4EB8B6"
                        />
                        <path
                            d="M236.685 172.341C236.658 172.328 236.64 172.303 236.637 172.273L235.773 164.015C235.603 162.389 236.268 160.82 237.601 159.706L239.467 158.006C239.495 157.98 239.537 157.977 239.57 157.996C239.603 158.016 239.618 158.054 239.61 158.091L239.468 158.645C240.877 158.143 242.41 158.168 243.802 158.717C245.281 159.301 246.395 160.426 246.869 161.809C248.455 162.041 249.845 162.881 250.692 164.122C251.658 165.537 251.79 167.267 251.063 168.806L252.161 168.143C252.199 168.12 252.249 168.129 252.275 168.165C252.301 168.201 252.296 168.251 252.264 168.281L249.769 170.525C248.75 171.429 247.402 171.957 245.968 172.012L236.731 172.349C236.716 172.349 236.702 172.346 236.689 172.339L236.685 172.341ZM239.368 158.329L237.713 159.837C236.424 160.914 235.781 162.431 235.944 163.997L236.799 172.176L245.956 171.843C247.351 171.789 248.66 171.277 249.649 170.399L251.468 168.763L250.888 169.113C250.855 169.133 250.813 169.128 250.784 169.101C250.755 169.074 250.749 169.032 250.768 168.997C251.614 167.452 251.53 165.667 250.544 164.222C249.716 163.008 248.346 162.19 246.787 161.977C246.755 161.972 246.728 161.949 246.718 161.919C246.27 160.562 245.182 159.456 243.733 158.882C242.331 158.327 240.782 158.323 239.369 158.867C239.339 158.879 239.305 158.874 239.281 158.85C239.257 158.829 239.247 158.795 239.255 158.764L239.366 158.33L239.368 158.329Z"
                            fill="#333333"
                        />
                        <path
                            d="M238.064 162.011C237.874 160.197 238.746 158.466 240.395 157.378C242.046 156.292 244.129 156.077 245.97 156.805C247.457 157.393 248.546 158.509 249.005 159.896C250.594 160.114 251.973 160.935 252.822 162.179C253.874 163.72 253.921 165.606 252.95 167.225C251.977 168.844 250.19 169.857 248.165 169.933L238.928 170.27L238.064 162.011Z"
                            fill="#4EB8B6"
                        />
                        <path
                            d="M238.89 170.347C238.863 170.334 238.845 170.31 238.842 170.28L237.978 162.021C237.785 160.174 238.671 158.412 240.349 157.308C242.023 156.206 244.136 155.989 246.003 156.726C247.483 157.31 248.597 158.434 249.07 159.818C250.656 160.05 252.048 160.89 252.894 162.13C253.964 163.7 254.013 165.622 253.024 167.27C252.037 168.913 250.222 169.943 248.168 170.02L238.931 170.356C238.916 170.357 238.902 170.354 238.89 170.347ZM246.379 157.08C246.237 157.009 246.09 156.944 245.94 156.885C244.126 156.168 242.071 156.38 240.444 157.451C238.821 158.521 237.963 160.222 238.151 162.003L239.007 170.182L248.163 169.848C250.158 169.773 251.921 168.774 252.877 167.181C253.831 165.592 253.785 163.74 252.751 162.227C251.924 161.014 250.554 160.195 248.993 159.982C248.961 159.977 248.933 159.954 248.923 159.924C248.521 158.707 247.607 157.691 246.378 157.081L246.379 157.08Z"
                            fill="#333333"
                        />
                        <path
                            d="M231.266 175.486C231.202 174.877 231.495 174.294 232.05 173.928C232.606 173.562 233.306 173.491 233.925 173.734C234.424 173.932 234.791 174.306 234.944 174.774C235.479 174.846 235.943 175.123 236.228 175.542C236.582 176.06 236.598 176.694 236.27 177.239C235.944 177.784 235.342 178.123 234.661 178.149L231.555 178.263L231.264 175.485L231.266 175.486Z"
                            fill="#F79092"
                        />
                        <path
                            d="M231.519 178.341C231.493 178.328 231.474 178.303 231.472 178.273L231.181 175.495C231.113 174.853 231.42 174.24 232.003 173.857C232.582 173.476 233.313 173.401 233.957 173.655C234.459 173.852 234.839 174.231 235.01 174.698C235.544 174.785 236.012 175.073 236.301 175.494C236.674 176.042 236.691 176.711 236.347 177.284C236.005 177.854 235.378 178.209 234.668 178.237L231.563 178.349C231.547 178.349 231.534 178.346 231.521 178.34L231.519 178.341ZM234.036 173.879C233.99 173.856 233.943 173.835 233.893 173.816C233.3 173.581 232.628 173.651 232.098 174C231.571 174.348 231.292 174.899 231.352 175.478L231.634 178.174L234.659 178.065C235.311 178.039 235.885 177.715 236.198 177.196C236.507 176.681 236.492 176.082 236.157 175.592C235.889 175.197 235.441 174.931 234.934 174.862C234.902 174.858 234.875 174.835 234.864 174.805C234.734 174.411 234.438 174.08 234.037 173.882L234.036 173.879Z"
                            fill="#F79092"
                        />
                        <path
                            d="M236.176 41.6455C236.176 41.6455 236.576 45.3715 238.13 46.9264C239.685 48.4813 243.411 48.8805 243.411 48.8805C243.411 48.8805 239.685 49.2797 238.13 50.8346C236.576 52.3895 236.176 56.1155 236.176 56.1155C236.176 56.1155 235.777 52.3895 234.222 50.8346C232.667 49.2797 228.941 48.8805 228.941 48.8805C228.941 48.8805 232.667 48.4813 234.222 46.9264C235.777 45.3715 236.176 41.6455 236.176 41.6455Z"
                            fill="#FFDF8A"
                        />
                        <path
                            d="M236.176 41.6455C236.176 41.6455 236.576 45.3715 238.13 46.9264C239.685 48.4813 243.411 48.8805 243.411 48.8805C243.411 48.8805 239.685 49.2797 238.13 50.8346C236.576 52.3895 236.176 56.1155 236.176 56.1155C236.176 56.1155 235.777 52.3895 234.222 50.8346C232.667 49.2797 228.941 48.8805 228.941 48.8805C228.941 48.8805 232.667 48.4813 234.222 46.9264C235.777 45.3715 236.176 41.6455 236.176 41.6455Z"
                            fill="#D5DDDC"
                        />
                        <path
                            d="M231.359 51.5479C231.359 51.5479 231.645 54.2113 232.756 55.3228C233.868 56.4343 236.531 56.7197 236.531 56.7197C236.531 56.7197 233.868 57.005 232.756 58.1165C231.645 59.2281 231.359 61.8915 231.359 61.8915C231.359 61.8915 231.074 59.2281 229.962 58.1165C228.851 57.005 226.187 56.7197 226.187 56.7197C226.187 56.7197 228.851 56.4343 229.962 55.3228C231.074 54.2113 231.359 51.5479 231.359 51.5479Z"
                            fill="#FFDF8A"
                        />
                        <path
                            d="M231.359 51.5479C231.359 51.5479 231.645 54.2113 232.756 55.3228C233.868 56.4343 236.531 56.7197 236.531 56.7197C236.531 56.7197 233.868 57.005 232.756 58.1165C231.645 59.2281 231.359 61.8915 231.359 61.8915C231.359 61.8915 231.074 59.2281 229.962 58.1165C228.851 57.005 226.187 56.7197 226.187 56.7197C226.187 56.7197 228.851 56.4343 229.962 55.3228C231.074 54.2113 231.359 51.5479 231.359 51.5479Z"
                            fill="#D5DDDC"
                        />
                        <ellipse
                            cx="81.0813"
                            cy="179.94"
                            rx="4.1223"
                            ry="4.1223"
                            transform="rotate(-23.8412 81.0813 179.94)"
                            fill="#FF6673"
                        />
                        <ellipse
                            cx="55.3107"
                            cy="143.959"
                            rx="6.24806"
                            ry="6.24807"
                            transform="rotate(-23.8412 55.3107 143.959)"
                            fill="#64DFDB"
                        />
                        <mask id="path-45-inside-3_598_56084" fill="white">
                            <path
                                d="M215.413 34.2438C220.114 42.1195 222.272 51.2528 221.594 60.3995C220.916 69.5461 217.435 78.2614 211.624 85.358L187.195 65.3569C188.892 63.2837 189.909 60.7376 190.107 58.0654C190.305 55.3933 189.675 52.7251 188.302 50.4242L215.413 34.2438Z"
                            />
                        </mask>
                        <path
                            d="M215.413 34.2438C220.114 42.1195 222.272 51.2528 221.594 60.3995C220.916 69.5461 217.435 78.2614 211.624 85.358L187.195 65.3569C188.892 63.2837 189.909 60.7376 190.107 58.0654C190.305 55.3933 189.675 52.7251 188.302 50.4242L215.413 34.2438Z"
                            fill="#FF8892"
                            stroke="#685959"
                            stroke-width="0.48"
                            mask="url(#path-45-inside-3_598_56084)"
                        />
                    </svg>
                    <div class="flex text-sm p-4 rounded-xl bg-[#f8f8f8]">
                        <div class="flex flex-col items-start mr-12">
                            <div class="mb-4">
                                {{ $t('common.ad_account_id') }}:
                            </div>
                            <div class="mb-4">
                                {{ $t('common.ad_account_name') }}:
                            </div>
                            <div class="mb-4">
                                {{ $t('common.current_ad_account_balance') }}:
                            </div>
                            <!--                        <div class="mb-4">-->
                            <!--                            {{ $t('common.your_account_balance') }}:-->
                            <!--                        </div>-->
                        </div>
                        <div class="flex flex-col items-start">
                            <div class="mb-4 font-semibold text-[#000]">
                                {{ form_add_fund.advertiser_id }}
                            </div>
                            <div class="mb-4 font-semibold text-[#000]">
                                {{ form_add_fund.advertiser_name }}
                            </div>
                            <div class="mb-4 font-semibold text-[#000]">
                                {{
                                    form_add_fund.advertiser_balance
                                        | f__format_currency_vnd
                                }}
                            </div>
                            <!--                        <div class="mb-4 font-semibold text-[#000]">-->
                            <!--                            {{-->
                            <!--                                user_info?.store_current_money-->
                            <!--                                    | f__format_currency_vnd-->
                            <!--                            }}-->
                            <!--                            VND-->
                            <!--                        </div>-->
                        </div>
                    </div>
                </div>

                <div class="mt-6">
                    <div>
                        <span class="span-label">
                            {{ $t('page.payment.cash_amount') }}:
                        </span>
                    </div>
                    <div>
                        <el-form
                            ref="form_rules"
                            :rules="form_rules"
                            :model="form_add_fund"
                            label-position="left"
                        >
                            <el-form-item prop="cash_amount">
                                <el-input
                                    v-model="form_add_fund.cash_amount"
                                    size="small"
                                    type="number"
                                    :placeholder="
                                        $t(
                                            'input.placeholder.amount_must_at_least',
                                            { value: min_amount }
                                        )
                                    "
                                    @keyup.native="calculateRechargeFee"
                                ></el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>

                <div v-loading="loading.calculate" class="mt-6 px-1">
                    <div class="flex justify-between text-sm">
                        <div>
                            <div class="mb-4">
                                {{ $t('common.advertising_fee') }}:
                            </div>
                        </div>
                        <div>
                            <div class="mb-4 font-semibold text-[#000]">
                                {{
                                    form_add_fund.cash_amount
                                        | f__format_currency_vnd
                                }}
                            </div>
                        </div>
                    </div>

                    <template v-if="recharge_fee.items">
                        <div
                            v-for="item in recharge_fee.items"
                            :key="item.key"
                            class="flex text-sm justify-between"
                        >
                            <div>
                                <div class="mb-4">
                                    {{ $t(`common.${item.label}`) }}
                                    ({{ item.value
                                    }}{{
                                        item.calculation_type === 'percent'
                                            ? '%'
                                            : ''
                                    }}):
                                </div>
                            </div>
                            <div>
                                <div class="mb-4 font-semibold text-[#000]">
                                    {{ item.result | f__format_currency_vnd }}
                                </div>
                            </div>
                        </div>
                        <div class="flex text-sm justify-between">
                            <div>
                                <div class="mb-4">
                                    {{ $t('common.total_amount') }}:
                                </div>
                            </div>
                            <div>
                                <div class="mb-4 font-semibold text-[#000]">
                                    {{
                                        recharge_fee.total_amount
                                            | f__format_currency_vnd
                                    }}
                                </div>
                            </div>
                        </div>
                    </template>
                </div>

                <div>
                    <el-checkbox v-model="show_invoice">
                        {{ $t('page.payment.invoice') }}</el-checkbox
                    >

                    <div v-if="show_invoice" class="mt-4">
                        <div class="flex gap-2">
                            <label-outside-input-select
                                :label="$t('page.payment.company_name')"
                                class="flex-1"
                            >
                                <el-input
                                    :placeholder="
                                        $t('input.placeholder.please_input')
                                    "
                                    size="small"
                                    v-model="invoice.company_name"
                                ></el-input>
                            </label-outside-input-select>
                            <label-outside-input-select
                                :label="$t('page.payment.tax_code')"
                                class="flex-1"
                            >
                                <el-input
                                    :placeholder="
                                        $t('input.placeholder.please_input')
                                    "
                                    size="small"
                                    v-model="invoice.code"
                                ></el-input>
                            </label-outside-input-select>
                            <label-outside-input-select
                                :label="$t('page.payment.company_phone')"
                                class="flex-1"
                            >
                                <el-input
                                    :placeholder="
                                        $t('input.placeholder.please_input')
                                    "
                                    size="small"
                                    v-model="invoice.phone"
                                ></el-input>
                            </label-outside-input-select>
                        </div>
                        <div class="flex gap-2 mt-4">
                            <label-outside-input-select
                                :label="$t('page.payment.company_address')"
                                class="flex-1"
                            >
                                <el-input
                                    :placeholder="
                                        $t('input.placeholder.please_input')
                                    "
                                    size="small"
                                    v-model="invoice.address"
                                ></el-input>
                            </label-outside-input-select>

                            <label-outside-input-select
                                :label="$t('page.payment.company_email')"
                                class="flex-1"
                            >
                                <el-input
                                    :placeholder="
                                        $t('input.placeholder.please_input')
                                    "
                                    size="small"
                                    v-model="invoice.email"
                                ></el-input>
                            </label-outside-input-select>
                        </div>
                    </div>
                </div>

                <div class="mt-10 flex">
                    <!--                    <div class="flex-1">-->
                    <!--                        <el-button-->
                    <!--                            type="primary"-->
                    <!--                            size="small"-->
                    <!--                            @click="openLinkOnNewWindow"-->
                    <!--                        >-->
                    <!--                            {{ $t('page.payment.online_recharge') }}-->
                    <!--                        </el-button>-->
                    <!--                    </div>-->
                    <div class="flex-1 text-right">
                        <el-button
                            size="small"
                            type="default"
                            @click="add_fund_dialog = false"
                        >
                            {{ $t('button.close') }}
                        </el-button>
                        <el-button
                            type="primary"
                            size="small"
                            :loading="loading.add_fund"
                            @click="openLinkOnNewWindow"
                        >
                            {{ $t('page.payment.recharge') }}
                        </el-button>
                        <!--                        <el-button-->
                        <!--                            type="primary"-->
                        <!--                            size="small"-->
                        <!--                            icon="el-icon-check"-->
                        <!--                            :loading="loading.add_fund"-->
                        <!--                            @click="addFundToAdAccount"-->
                        <!--                        >-->
                        <!--                            {{ $t('button.confirm') }} (Hidden)-->
                        <!--                        </el-button>-->
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { drop as _drop, take as _take } from 'lodash'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import selectedTiktokBusinessMixin from '@/plugins/mixins/selected-tiktok-business'
import {
    addFund,
    calculateRechargeFee
} from '@/services/atosa-tiktok-ads/payment'
import {
    getAdAccountOnBoarding,
    getAdAccountOnBoardingDetail
} from '@/services/atosa-tiktok-ads/on-boarding'
import ValidateFormMixin from '@/plugins/mixins/validate-form'
import { getAdvertiserAccounts } from '@/services/atosa-tiktok-ads/advertiser-account'

export default {
    mixins: [
        selectedTiktokBusinessMixin,
        paginationDataMixin,
        ValidateFormMixin
    ],

    props: {
        selected_tiktok_business: null,
        user_info: null
    },

    data() {
        return {
            show_invoice: false,
            invoice: {
                code: '',
                address: '',
                email: '',
                phone: '',
                company_name: ''
            },
            search_keyword: '',
            loading: {
                get_data: false,
                add_fund: false,
                calculate: false
            },
            add_fund_dialog: false,
            ad_account_list: [],
            form_add_fund: {
                advertiser_id: null,
                advertiser_name: null,
                advertiser_balance: null,
                advertiser_currency: null,
                cash_amount: 3_000_000,
                bc_id: '',
                fee_amount: null
            },
            min_amount: 500_000,
            form_rules: {
                cash_amount: [
                    {
                        required: true,
                        message: this.$t('input.placeholder.please_input'),
                        trigger: 'blur'
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (Number(value) >= this.min_amount) {
                                callback()
                            } else {
                                callback(
                                    new Error(
                                        this.$t(
                                            'input.placeholder.amount_must_at_least',
                                            { value: this.min_amount }
                                        )
                                    )
                                )
                            }
                        },
                        trigger: 'blur'
                    }
                ]
            },
            recharge_fee: {}
        }
    },

    computed: {
        ad_account_list_filter_list() {
            let result = this.ad_account_list

            if (this.search_keyword) {
                result = result.filter(
                    (i) =>
                        i.advertiser_id === this.search_keyword ||
                        i.name
                            .toLowerCase()
                            .includes(this.search_keyword.toLowerCase())
                )
            }

            return result
        },

        ad_account_list_display_list() {
            const limit = this.m__page_info.page_size
            const skip = limit * (this.m__page_info.page - 1)

            return _take(_drop(this.ad_account_list_filter_list, skip), limit)
        }
    },

    watch: {
        'selected_tiktok_business.id': function () {
            this.getAllAdAccount()
        },

        'm__page_info.page': function () {
            this.getAdAccountDetail()
        },

        search_keyword: function () {
            this.getAdAccountDetail()
        },

        add_fund_dialog() {
            this.recharge_fee = {}
        },

        show_invoice() {
            this.calculateRechargeFee()
        }
    },

    async mounted() {
        this.m__page_info.page_size = this.m__list_pagesize[1].value

        window.addEventListener(
            'message',
            (msg) => {
                if (['payment_success'].includes(msg.data)) {
                    this.add_fund_dialog = false
                    this.getAllAdAccount()
                    this.p__showNotify(
                        'success',
                        this.$t('page.payment.add_fund_successfully')
                    )
                }
            },
            false
        )

        await this.getAllAdAccount()
    },

    methods: {
        async calculateRechargeFee() {
            try {
                await this.m__ValidateForm('form_rules')
            } catch (e) {
                this.recharge_fee = {}
                return
            }

            this.calculate = true
            try {
                const response = await calculateRechargeFee({
                    cash_amount: this.form_add_fund.cash_amount,
                    vat: this.show_invoice
                })
                this.recharge_fee = response.data
            } catch (e) {
                console.error(e)
            } finally {
                this.calculate = false
            }
        },

        async openLinkOnNewWindow() {
            try {
                await this.m__ValidateForm('form_rules')
            } catch (e) {
                return
            }

            await this.calculateRechargeFee()

            try {
                this.form_add_fund.fee_amount = this.recharge_fee.fee_amount
                const amount =
                    Number(this.form_add_fund.cash_amount) +
                    Number(this.recharge_fee.fee_amount)

                const temp = {
                    ...this.form_add_fund,
                    tiktok_account_id: this.selected_tiktok_business.id,
                    channel: 'tiktok_ads',
                    access_token: localStorage.getItem('token')
                }
                const advertiser_name = temp.advertiser_name
                temp.advertiser_name = temp.advertiser_name.replace(
                    /[^a-zA-Z0-9]/g,
                    ''
                )

                if (this.show_invoice) {
                    temp.i_s = '1'
                    temp.i_c = this.invoice.code
                    temp.i_a = this.invoice.address
                    temp.i_cn = this.invoice.company_name
                    temp.i_e = this.invoice.email
                    temp.i_p = this.invoice.phone
                }

                const extra = JSON.stringify(temp)

                await this.p__OpenLinkOnNewWindow(
                    `/payment/webview?tkcn=${
                        this.show_invoice ? 0 : 1
                    }&amount_options=${amount}&amount_allow_input=false&amount=${amount}&extra=${extra}&user_info_inject=TKQC:${advertiser_name}`
                )
            } catch (e) {
                console.error(e)
            }
        },

        async addFundToAdAccount() {
            try {
                await this.m__ValidateForm('form_rules')
            } catch (e) {
                return
            }

            // if (!this.form_add_fund.cash_amount) {
            //     this.p__showNotify(
            //         'warning',
            //         this.$t('page.payment.enter_cash_amount')
            //     )
            //     return
            // }

            if (
                !this.user_info.store_current_money ||
                this.user_info.store_current_money -
                    this.form_add_fund.cash_amount +
                    this.form_add_fund.fee_amount <
                    0
            ) {
                this.p__showNotify(
                    'warning',
                    this.$t('page.payment.your_balance_not_enough')
                )
                return
            }

            try {
                await this.p__showConfirm(
                    'common.note',
                    'page.payment.confirm_add_fund_message'
                )
            } catch (e) {
                return
            }

            try {
                this.loading.add_fund = true
                this.form_add_fund.fee_amount = this.recharge_fee.fee_amount
                await addFund(
                    this.selected_tiktok_business.id,
                    this.form_add_fund
                )
                await this.getAllAdAccount()
                this.$emit('getUserInfo')
                this.p__showNotify(
                    'success',
                    this.$t('page.payment.add_fund_successfully')
                )
                this.add_fund_dialog = false
            } catch (e) {
                console.error(e)
            } finally {
                this.loading.add_fund = false
            }
        },

        showAddFundDialog(data) {
            this.form_add_fund.advertiser_id = data.advertiser_id
            this.form_add_fund.advertiser_name = data.name
            this.form_add_fund.advertiser_balance = data.balance
            this.form_add_fund.advertiser_currency = data.currency
            this.form_add_fund.system_bc = data.owner_bc_id
            this.form_add_fund.cash_amount = null
            this.form_add_fund.fee_amount = null
            this.add_fund_dialog = true
        },

        async getAllAdAccount() {
            //             if (!this.selected_tiktok_business) return

            // const response = await getAdAccountOnBoarding(
            //     this.selected_tiktok_business.id
            // )
            // for (const item of response.data) {
            //     item.data = {}
            // }
            // this.ad_account_list = response.data

            // await this.getAdAccountDetail()

            if (!this.selected_tiktok_business) return

            const response = await getAdvertiserAccounts(
                this.selected_tiktok_business.id,
                ''
            )
            console.log(response.data)
            for (const item of response.data) {
                item.data = {}
            }
            this.ad_account_list = response.data.filter(
                (item) => item.paymentable
            )

            await this.getAdAccountDetail()

            const { advertiser_id } = this.$route.query
            if (advertiser_id) {
                const found = this.ad_account_list.find(
                    (i) => i.advertiser_id === advertiser_id
                )
                if (found) {
                    // if (!found.data) {
                    //     const detail_response =
                    //         await getAdAccountOnBoardingDetail(
                    //             this.selected_tiktok_business.id,
                    //             [found.advertiser_id]
                    //         )
                    //     for (const item of detail_response.data) {
                    //         const found = this.ad_account_list.find(
                    //             (i) => i.advertiser_id === item.advertiser_id
                    //         )
                    //         if (found) found.data = item
                    //     }
                    // }

                    this.showAddFundDialog(found)
                }
            }
        },

        async getAdAccountDetail() {
            // const ad_account_ids = this.ad_account_list_display_list.map(
            //     (i) => i.advertiser_id
            // )
            // if (!ad_account_ids.length) return
            // try {
            //     this.loading.get_data = true
            //     // const detail_response = await getAdvertiserAccountDetail(
            //     //     this.selected_tiktok_business.id,
            //     //     ad_account_ids
            //     // )
            //     const detail_response = await getAdAccountOnBoardingDetail(
            //         this.selected_tiktok_business.id,
            //         ad_account_ids
            //     )
            //     for (const item of detail_response.data) {
            //         const found = this.ad_account_list.find(
            //             (i) => i.advertiser_id === item.advertiser_id
            //         )
            //         if (found) found.data = item
            //     }
            // } catch (e) {
            //     console.error(e)
            // } finally {
            //     this.loading.get_data = false
            // }
        },

        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                const { property } = column

                const values = data.map((item) => {
                    if (property) {
                        const pathArray = property.split('.')
                        if (pathArray.length > 1) {
                            let value = item
                            for (const key of pathArray) {
                                value = value[key]
                            }

                            return Number(value)
                        }
                    }

                    return Number(item[property])
                })

                if (!values.every((value) => isNaN(value))) {
                    const total = this.calculateValue(values, property, data)

                    sums[index] = total
                } else {
                    sums[index] = ''
                }
            })
            return sums
        },

        calculateValue(values, column_property, data) {
            let total = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                    return prev + curr
                } else {
                    return prev
                }
            }, 0)

            if (['balance'].some((item) => column_property === item)) {
                total = this.p__renderMoneyByCurrency(total, data[0].currency)
            }
            return total
        }
    }
}
</script>

<style>
.el-tooltip-text-medium {
    font-size: 0.75rem;
}
</style>
