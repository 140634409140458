<template>
    <div>
        <div class="flex justify-end space-x-2 mb-4">
            <el-date-picker
                v-model="ranger_date"
                class="w-[30%]"
                size="small"
                type="daterange"
                popper-class="custom-el-date"
                unlink-panels
                :start-placeholder="$t('date_picker.placeholder.start')"
                :end-placeholder="$t('date_picker.placeholder.end')"
                :format="$t('common.format_value_date_picker')"
                value-format="yyyy-MM-dd"
                :picker-options="m__picker_options"
                @change="getTransactionList"
            >
            </el-date-picker>

            <el-button
                type="default"
                size="small"
                icon="el-icon-refresh"
                @click="getTransactionList"
            ></el-button>

            <div
                class="cursor-pointer p-2 rounded-lg bg-bg"
                @click="handleExportExcel"
            >
                <el-tooltip
                    class="item"
                    :content="$t(`common.export_excel`)"
                    effect="dark"
                    placement="top"
                >
                    <ExcelSvg></ExcelSvg>
                </el-tooltip>
            </div>
        </div>

        <el-table
            v-loading="m__loading"
            max-height="400"
            :data="transaction_display_list"
            show-summary
            :summary-method="getSummaries"
        >
            <el-table-column label="#" type="index" width="60" />

            <el-table-column :label="$t('common.ad_account')">
                <template slot-scope="scope">
                    <p>ID: {{ scope.row.advertiser_id }}</p>
                    <p v-if="findName(scope.row.advertiser_id)">
                        {{ findName(scope.row.advertiser_id) }}
                    </p>
                </template>
            </el-table-column>
            <el-table-column
                prop="cash_amount"
                :label="$t('common.cash_amount')"
            >
                <template slot-scope="scope">
                    +{{ scope.row.cash_amount | f__format_currency_vnd }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.balance_type')">
                <template>
                    {{ $t('common.transfers') }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.created_at')">
                <template slot-scope="scope">
                    {{
                        scope.row.created_at
                            | f__format_moment($t('common.format_fulldate'))
                    }}
                </template>
            </el-table-column>
            <el-table-column :label="$t('common.operator')">
                <template slot-scope="scope">
                    {{ scope.row.created_by?.display_name }}
                </template>
            </el-table-column>
        </el-table>
    </div>
</template>

<script>
import moment from 'moment'
import { drop as _drop, take as _take } from 'lodash'
import { getAdAccountTransactionHistory } from '@/services/atosa-tiktok-ads/payment'
import paginationDataMixin from '@/plugins/mixins/pagination-data'
import { getAdvertiserAccounts } from '@/services/atosa-tiktok-ads/advertiser-account'
import rangerDateMixin from '@/plugins/mixins/ranger-date'
import ExcelSvg from '@/assets/svgs/excel.svg'
import { exportExcel } from '@/services/atosa-tiktok-ads/reporting'

export default {
    components: {
        ExcelSvg
    },

    mixins: [paginationDataMixin, rangerDateMixin],

    props: ['selected_tiktok_business'],

    data() {
        return {
            ad_account_list: [],
            loading: {
                get_data: false
            },
            ranger_date: [
                new Date(new Date().getTime() - 3600 * 1000 * 24 * 30),
                new Date()
            ],
            transaction_list: []
        }
    },

    computed: {
        transaction_display_list() {
            const limit = this.m__page_info.page_size
            const skip = limit * (this.m__page_info.page - 1)

            return _take(_drop(this.transaction_list, skip), limit)
        }
    },

    watch: {
        'selected_tiktok_business.id': function () {
            this.getAllAdAccount()
        }
    },

    beforeMount() {
        this.getTransactionList()
    },

    methods: {
        findName(advertiser_id) {
            if (this.ad_account_list.length === 0) {
                return ''
            }

            const item = this.ad_account_list.find(
                (it) => it.advertiser_id === advertiser_id
            )

            if (item) {
                return item.name
            }

            return ''
        },

        async getAllAdAccount() {
            if (!this.selected_tiktok_business) return

            const response = await getAdvertiserAccounts(
                this.selected_tiktok_business.id,
                ''
            )
            for (const item of response.data) {
                item.data = {}
            }
            this.ad_account_list = response.data.filter(
                (item) => item.belong_to_atosa
            )
        },

        async getTransactionList() {
            this.m__loading = true

            try {
                const start_time = moment(this.ranger_date[0]).format(
                    'YYYY-MM-DD'
                )
                const end_time = moment(this.ranger_date[1]).format(
                    'YYYY-MM-DD'
                )
                const response = await getAdAccountTransactionHistory(
                    start_time,
                    end_time
                )
                this.transaction_list = response.data
            } catch (error) {
                console.error(error)
            }

            this.m__loading = false
        },

        getSummaries(param) {
            const { columns, data } = param
            const sums = []
            columns.forEach((column, index) => {
                const { property } = column

                const values = data.map((item) => {
                    if (property) {
                        const pathArray = property.split('.')
                        if (pathArray.length > 1) {
                            let value = item
                            for (const key of pathArray) {
                                value = value[key]
                            }

                            return Number(value)
                        }
                    }

                    return Number(item[property])
                })

                if (!values.every((value) => isNaN(value))) {
                    const total = this.calculateValue(values, property, data)

                    sums[index] = total
                } else {
                    sums[index] = ''
                }
            })
            return sums
        },

        calculateValue(values, column_property, data) {
            let total = values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                    return prev + curr
                } else {
                    return prev
                }
            }, 0)

            if (['cash_amount'].some((item) => column_property === item)) {
                total = this.p__renderMoneyByCurrency(
                    total,
                    data[0].advertiser_currency
                )
            }
            return total
        },

        async handleExportExcel() {
            const start_time = moment(this.ranger_date[0]).format('DD-MM-YYYY')
            const end_time = moment(this.ranger_date[1]).format('DD-MM-YYYY')

            const headers = [
                'ID',
                this.$t('common.ad_account'),
                this.$t('common.cash_amount'),
                this.$t('common.created_at'),
                this.$t('common.operator')
            ]

            const body = this.transaction_list.map((item) => {
                return [
                    item.advertiser_id,
                    this.findName(item.advertiser_id),
                    this.p__renderMoneyByCurrency(item.cash_amount, 'VND'),
                    moment(item.created_at).format(
                        this.$t('common.format_fulldate')
                    ),
                    item.created_by?.display_name || ''
                ]
            })

            const name = `history_${this.mail}_${start_time}_${end_time}`

            const response = await exportExcel({
                name,
                headers,
                body
            })

            this.p__exportExcelByBlob(response, name)
        }
    }
}
</script>
